export default {
  steam: "mdi-steam",
  switch: "mdi-nintendo-switch",
  playstation: "mdi-sony-playstation",
  xbox: "mdi-microsoft-xbox",
  android: "mdi-android",
  ios: "mdi-apple",
  itchio: "mdi-microsoft-xbox",
  web: "mdi-earth",
};
